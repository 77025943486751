<template>
  <div class="search-cadnumber">
    <section class="panel is-primary">
      <h1 class="title is-6 panel-heading mb-0">
        Информация по кадастровому номеру
      </h1>
      <div class="cadnumber-data box">
        <div class="search-cadnumber_block">
          <b-field
            label="Кадастровый номер"
            :type="{
              'is-danger': $v.cadNumber.$error,
              'is-success': !$v.cadNumber.$invalid && $v.cadNumber.$dirty,
            }"
            :message="{
              'Некорректно указан кадастровый номер': $v.cadNumber.$error,
            }"
          >
            <IMaskComponent
              placeholder="Введите кадастровый номер"
              inputmode="numeric"
              class="input"
              :class="{
                'is-danger': $v.cadNumber.$error,
                'is-success': !$v.cadNumber.$invalid && $v.cadNumber.$dirty,
              }"
              :mask="'00{:}00{:}000[0000]{:}0[000000]'"
              v-model.trim="cadNumber"
              @blur="onTouchField('cadNumber')"
            />
          </b-field>
          <div class="button-wrapper">
            <b-button type="is-info" expanded @click.prevent="searchByCadNumber"
              >Поиск</b-button
            >
          </div>
        </div>
        <div class="search-cadnumber_result">
          <!-- <h2 class="title is-4 mb-6">Информация по результату поиска:</h2> -->
          <div
            class="info-main"
            v-if="
              realEstateObject !== null && Object.keys(realEstateObject).length
            "
          >
            <h5 class="title is-5 mb-0">Информация об объекте:</h5>
            <ul>
              <li><b>Кадастровый номер:</b> {{ realEstateObject.CADNOMER }}</li>
              <li><b>Адрес:</b> {{ realEstateObject.ADDRESS }}</li>
              <li><b>Тип:</b> {{ realEstateObject.TYPE }}</li>
              <li><b>Площадь:</b> {{ realEstateObject.AREA }}</li>
              <li><b>Категория:</b> {{ realEstateObject.CATEGORY }}</li>
            </ul>
          </div>
          <div
            class="info-details"
            v-if="details !== null && Object.keys(details).length"
          >
            <h5 class="title is-5 mb-0">Детальная информация:</h5>
            <ul>
              <li v-for="(value, name) in details" :key="name">
                <b>{{ name }}:</b> {{ value }}
              </li>
            </ul>
          </div>
          <div class="info-own" v-if="own !== null && own.length">
            <h5 class="title is-5 mb-0">Информация о правах:</h5>
            <ul
              v-for="(obj, mainIndex) in own"
              :key="mainIndex"
              class="ul-main"
            >
              <li><b>Дата:</b> {{ obj.date }}</li>
              <li><b>Тип:</b> {{ obj.type }}</li>
              <li><b>Номер:</b> {{ obj.number }}</li>
              <li class="li-main" v-if="obj.limits.length">
                <h6 class="title is-6 mb-0">Ограничения:</h6>
                <ul
                  v-for="(itemObj, childIndex) in obj.limits"
                  :key="`${mainIndex}_${childIndex}1`"
                  class="ul-child"
                >
                  <li><b>Дата:</b> {{ itemObj.date }}</li>
                  <li><b>Тип:</b> {{ itemObj.type }}</li>
                  <li><b>Номер:</b> {{ itemObj.number }}</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { IMaskComponent } from 'vue-imask';
import { minLength, maxLength, required } from 'vuelidate/lib/validators';
import { onTouchField } from '@/utils/helpers';

export default {
  name: 'SearchCadNumber',
  data() {
    return {
      cadNumber: '',
      onTouchField,
      fullInfo: {},
    };
  },
  async created() {
    this.$store.commit('toggleLoader', true);
    await this.$store.dispatch('getme');
    this.$store.commit('toggleLoader', false);
  },
  computed: {
    realEstateObject() {
      return Object.prototype.hasOwnProperty.call(this.fullInfo, 'result') &&
        Object.keys(this.fullInfo.result).length
        ? this.fullInfo.result
        : {};
    },
    own() {
      return Object.prototype.hasOwnProperty.call(this.fullInfo, 'own') &&
        this.fullInfo.own.length
        ? this.fullInfo.own
        : [];
    },
    details() {
      return Object.prototype.hasOwnProperty.call(this.fullInfo, 'details') &&
        Object.keys(this.fullInfo.details).length
        ? this.fullInfo.details
        : {};
    },
  },
  methods: {
    convertData(obj) {
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const item = obj[key];

          if (item === null) {
            obj[key] = '—';
          } else if (Array.isArray(item)) {
            item.forEach((itemObj) => {
              this.convertData(itemObj);
            });
          } else if (typeof item === 'object') {
            this.convertData(item);
          }
        }
      }
    },
    clearResult(result) {
      const data = JSON.parse(JSON.stringify(result));

      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const item = data[key];

          if (item === null) {
            delete data[key];
          }
        }
      }

      return data;
    },
    async searchByCadNumber() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const success = await this.$store.dispatch(
        'search/searchByCadNumber',
        this.cadNumber
      );

      if (success) {
        const result = this.clearResult(
          this.$store.state.search.searchByCadNumberResult
        );

        this.convertData(result);
        this.fullInfo = result;
      } else {
        this.fullInfo = {};
      }
    },
  },
  validations: {
    cadNumber: {
      minLength: minLength(11),
      maxLength: maxLength(21),
      required,
    },
  },
  components: { IMaskComponent },
};
</script>

<style lang="scss">
.search-cadnumber {
  .cadnumber-data {
    display: flex;
    align-items: stretch;

    @media screen and (max-width: 640px) {
      flex-direction: column;
    }

    .search-cadnumber_block {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 300px;
      min-width: 180px;
      width: 100%;

      @media screen and (max-width: 640px) {
        max-width: 100%;
      }

      .button-wrapper {
        button {
          margin-bottom: 0.25rem;
        }
      }
    }

    .search-cadnumber_result {
      border-left: 2px solid #496ca1;
      padding-left: 20px;
      margin-left: 20px;

      @media screen and (max-width: 640px) {
        border-top: 2px solid #496ca1;
        border-left: none;
        padding-left: 0px;
        padding-top: 20px;
        margin-left: 0px;
        margin-top: 20px;
      }

      .info-main,
      .info-own {
        margin-bottom: 20px;
      }

      ul {
        list-style: none;
        margin: 15px 0;

        &.ul-main {
          border-bottom: 1px solid #496ca2;

          &:last-of-type {
            border-bottom: none;
          }
        }

        li {
          font-size: 15px;
          line-height: 20px;
          padding: 5px 0;

          &.li-main {
            margin-top: 15px;

            ul {
              margin: 10px 0;

              li {
                padding: 0;
              }
            }
          }
        }
      }

      .ul-child {
        margin-top: 10px;
      }
    }
  }
}
</style>
